/* eslint-disable complexity */
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ToasterService } from '@purespectrum1/ui/toaster-service';
import {
  EachCountry,
  EachLanguage,
} from 'src/app/shared/interfaces/common-service.interface';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import {
  Answer,
  DataQuality,
  Options,
  Localization,
  AnswerOptionUI,
  AnswerOptionAPI,
  QualificationOptions,
} from '../../questions-library-services/questions-library-services.interface';
import { CommonService } from '../../../shared/services/common-service/common-service';
import { Constants, messages } from '../../questions-library.constants';
import { notifyMessage } from '../../../constants/notify-message';
import { DependencyComponent } from './dependency/dependency.component';
import { SegmentationComponent } from './segmentation/segmentation.component';
import { AuthService } from '../../../auth/auth.service';
import { debounceTime } from 'rxjs/operators';
import { QuestionsLibraryService } from '../../questions-library-services/questions-library-services.service';

@Component({
  selector: 'ps-question-localization',
  templateUrl: './localization.component.html',
  styleUrls: ['./localization.component.css'],
})
export class LocalizationComponent implements OnInit, OnDestroy {
  @Input() questionCategory?: string = '';
  @Input() questionType: number = 1;
  @Input() class?: number;
  @Input() localizationAdded?: Localization;
  @Input() clonedLocalization?: Localization;
  @Input() isEditLocalizationMode: boolean = false;
  @Input() localizations: Localization[] = [];
  @Input() isPrivacyPolicyQuestion: boolean = false;
  @Input() isEditMode?: boolean;
  @Input() questionId?: number;
  @Input() index: number = 0;
  @Input() isSegmentation: boolean = false;
  @Output() localizationsChange = new EventEmitter<Localization[]>();

  @ViewChild(DependencyComponent) dependencyComponent!: DependencyComponent;
  @ViewChild(DependencyComponent)
  set _dependencyComponent(_: DependencyComponent) {
    this._setDependencyLocale();
  }

  @ViewChild(SegmentationComponent)
  segmentationComponent!: SegmentationComponent;
  @ViewChild(SegmentationComponent)
  set _segmentationComponent(_: SegmentationComponent) {
    this._setSegmentationLocale();
  }

  public form!: FormGroup;
  public countries: EachCountry[] = [];
  public languages: EachLanguage[] = [];
  public dpOptions: Options[] = [];
  public dependencyOptions: Options[] = [];
  public dqOptions: Options[] = [];
  public placeholders: Array<string> = [];
  public isDisableRespIdBtn: boolean = false;
  public qualityRules: DataQuality[] = [];
  public userType: string = '';
  public imageUploading = false;
  public isPlaceHolderDropdownShow = false;
  public isUserHasQuestionLibraryAccess: boolean = false;

  // PD-8499
  public create_survey_position: Options[] = [];
  public create_survey_component: Options[] = [];

  private _subscriptions: Subscription = new Subscription();

  readonly CONSTANTS = Constants;

  constructor(
    private _authService: AuthService,
    private _commonService: CommonService,
    private _toastr: ToasterService,
    private _fb: FormBuilder,
    private _questionsLibraryService: QuestionsLibraryService
  ) {
    this.dpOptions = Constants.DP_OPTIONS;
    this.placeholders = Constants.QUESTION_PLACEHOLDER;
    this.dependencyOptions = Constants.DEPENDENCY_OPTIONS;
    this.dqOptions = Constants.DEPENDENCY_CONDITION;
    this.userType = this._authService.userType;
    this.create_survey_position = [];
    this.create_survey_component = [];
  }

  ngOnInit(): void {
    this._generateForm();
    this._getCountries();
    if (this.isEditLocalizationMode) {
      this._subscriptions.add(
        this.form.valueChanges.pipe(debounceTime(500)).subscribe(() => {
          this.updateLocalizationObject();
        })
      );
    }
    this.isUserHasQuestionLibraryAccess =
      !!this._authService.user?.questionLibraryAccessLevel;
  }

  addTypedPlaceholder(newItem: string) {
    this.isPlaceHolderDropdownShow = false;
    this.form.controls.placeholder.setValue(newItem);
  }

  togglePlaceHolderDropdown() {
    this.isPlaceHolderDropdownShow = !this.isPlaceHolderDropdownShow;
  }

  private _generateForm() {
    this.form = this._fb.group({
      selectedCountry: ['', Validators.required],
      selectedLanguage: ['', Validators.required],
      enableDP: this._fb.group({
        key: false,
        value: 'false',
      }),
      create_survey_position: [''],
      create_survey_component: [''],
      name_alias: [''],
      dpPriority: [0],
      enableDependency: [false],
      enableSegmentation: [false],
      enableNonGdpr: [false],
      ppPlaceholderText: [''],
      ppModalText: [''],
      enableDataquality: [false],
      screenerText: [''],
      placeholder: [''],
      answers: this._fb.array([this.addEmptyAnswerAtTop()]),
      has_census_data: [false],
      imageUrl: [null],
      eligibleForOETermination: [false],
      enableSegmentationDependentQuestion: [false],
    });
  }

  private _setDefaultForm() {
    if (!this.create_survey_position.length) {
      this.create_survey_position = Constants.POSITION_OPTIONS;
      // by default showing advance view position
      this.form.controls.create_survey_position.setValue({
        key: 0,
        value: 'Advance Targeting search view',
      });

      this.create_survey_component = Constants.ALL_COMPONENT_OPTIONS.filter(
        (component) => {
          return component.type === 'advance';
        }
      );
      this.form.controls.create_survey_component.setValue(
        this.create_survey_component.find((component) => component.key === 0)
      );
    }
  }

  get answers(): FormArray {
    return this.form.get('answers') as FormArray;
  }

  addEmptyAnswerAtTop(): FormGroup {
    let id = Constants.LOCALIZATION.ANSWERS.DEFAULT_CONDITION_CODE;
    if (this.form?.controls?.answers?.value?.length) {
      // Auto increment condition code
      id = this._getOptionForEmptyRow(this.answers.value);
    }
    return this._fb.group({
      id: [id, Validators.required],
      text: [''],
      translation: [''],
      totalSelectedText: ['Total Selected: 0'],
    });
  }

  addAnswerOption() {
    const upcomingOption: AnswerOptionUI = this.answers.value[0];
    // If 'Id' or 'text' is missing
    if (!upcomingOption.id || !upcomingOption.text) {
      return this._toastr.warning(
        notifyMessage.warningMessage.QUESTION_LIBRARY.REQUIRED_ANSWER_OPTIONS
      );
    }

    // If for non-english locale, translation field is not filled
    if (this.isNonEnglishLocale && !upcomingOption.translation) {
      return this._toastr.warning(
        notifyMessage.warningMessage.QUESTION_LIBRARY
          .REQUIRED_ANSWER_TRANSLATION
      );
    }

    // Separating texts and translations based on delimiter (line break is the current delimeter for our purpose)
    const upcomingOptionTexts = this.separateTextsByDelimiter(
      upcomingOption.text
    );
    const upcomingOptionTranslations = this.separateTextsByDelimiter(
      upcomingOption.translation
    );

    // Verifying texts and translations post delimiter based separation; If verified, then adding the answers
    const isVerified = this.verifyAndAddAnswerOptions(
      upcomingOptionTexts,
      upcomingOptionTranslations
    );
    if (!isVerified) {
      return;
    }
    this._toastr.success(
      notifyMessage.successMessage.QUESTION_LIBRARY.ANSWER_OPTION_ADDED
    );
  }

  separateTextsByDelimiter(text = '') {
    return text
      .split(this.CONSTANTS.LOCALIZATION.ANSWERS.TEXT_DELIMITER)
      .filter((item) => !!item)
      .map((item) => item.trim()); // Trimming the spaces on either of the ends of the texts
  }

  verifyAndAddAnswerOptions(
    upcomingOptionTexts: string[],
    upcomingOptionTranslations: string[] = []
  ): boolean {
    // Removing the duplicates in texts and translations
    [upcomingOptionTexts, upcomingOptionTranslations] =
      this.removeDuplicateOptionTextsAndTranslations(
        upcomingOptionTexts,
        upcomingOptionTranslations
      );

    // In case, all the options were duplicates and hence removed, then throwing warning
    if (!upcomingOptionTexts.length) {
      this._toastr.warning(
        notifyMessage.warningMessage.QUESTION_LIBRARY.OPTION_TEXT_ALREADY_EXISTS
      );
      return false;
    }

    // If for a non-english localization, the text count and translation count do not match, then throw warning
    if (
      this.isNonEnglishLocale &&
      upcomingOptionTexts.length !== upcomingOptionTranslations.length
    ) {
      this._toastr.warning(
        notifyMessage.warningMessage.QUESTION_LIBRARY
          .ANSWER_AND_TRANSLATION_MISMATCH
      );
      return false;
    }

    // Adding answer options
    const badOptionIds = [];
    upcomingOptionTexts.forEach((singleText, index) => {
      const currentAnswer: AnswerOptionUI = this.answers.value[0];
      const isVerified = this.verifySingleAnswerOptionId();
      if (!isVerified) {
        badOptionIds.push(currentAnswer.id);
        return;
      }

      const singleOptionText = singleText;
      const singleOptionTranslation = upcomingOptionTranslations[index] || '';

      // Patching current Option row, and then inserting a new row at the top
      this.patchAnswerAt(
        0,
        currentAnswer.id,
        singleOptionText,
        singleOptionTranslation
      );
      this.answers.insert(0, this.addEmptyAnswerAtTop());
    });
    return !badOptionIds.length;
  }

  removeDuplicateOptionTextsAndTranslations(
    textArray: string[] = [],
    translationArray: string[] = []
  ): [string[], string[]] {
    const existingAnswers: AnswerOptionUI[] = this.answers.value.filter(
      (val: AnswerOptionUI, i: number) => Number(i) !== 0
    );
    return textArray.reduce(
      (
        [finalOptionTexts, finalOptionTranslations],
        currentText: string,
        currentIndex: number
      ) => {
        // If a text is already added, do not add it again
        const alreadyAddedText = finalOptionTexts.find(
          (text) => text === currentText
        );
        if (alreadyAddedText) {
          return [finalOptionTexts, finalOptionTranslations];
        }

        const foundAnswerText = existingAnswers.find(
          (val: AnswerOptionUI) => val.text === currentText
        );

        // If the answer text is not present in the existing answer texts, then only allowing that to add
        if (!foundAnswerText) {
          if (textArray[currentIndex]) {
            finalOptionTexts.push(textArray[currentIndex]);
          }
          if (translationArray[currentIndex]) {
            finalOptionTranslations.push(translationArray[currentIndex]);
          }
        }
        return [finalOptionTexts, finalOptionTranslations];
      },
      [<string[]>[], <string[]>[]]
    );
  }

  patchAnswerAt(
    index: number,
    answerId: number,
    answerText: string,
    answerTanslation: string
  ) {
    this.answers.at(index).patchValue({
      id: Number(answerId),
      text: answerText,
      translation: answerTanslation,
    });
  }

  verifySingleAnswerOptionId(): boolean {
    const currentAnswer: AnswerOptionUI = this.answers.value[0];
    const existingAnswers: AnswerOptionUI[] = this.answers.value.filter(
      (val: AnswerOptionUI, i: number) => Number(i) !== 0
    );
    // If the Option Id already exists
    if (
      existingAnswers &&
      existingAnswers.find(
        (answer) => Number(answer.id) === Number(currentAnswer.id)
      )
    ) {
      this._toastr.warning(
        notifyMessage.warningMessage.QUESTION_LIBRARY.ANSWER_ID_ALREADY_EXISTS
      );
      return false;
    }
    return true;
  }

  public updateLocalizationObject() {
    const formValue = this.form.value;
    // remove default answer at first position
    if (formValue.answers.length > 0 && formValue.answers[0].text === '') {
      formValue.answers.shift();
    }
    const oldLocalization = this.localizations[this.index];
    this.localizations[this.index] = this._prepareLocalizationObject(
      formValue,
      oldLocalization
    );
    this.localizationsChange.emit(this.localizations);
  }

  deleteAnswerOption(index: number): void {
    this.answers.removeAt(index);
    this._toastr.success(
      notifyMessage.successMessage.QUESTION_LIBRARY.ANSWER_OPTION_DELETED
    );
  }

  private _getCountries() {
    const countries = this._commonService.getCountries().subscribe(
      (countriesData) => {
        this.countries = countriesData;
        this.selectCountry(countriesData[0]);
        if (this.localizationAdded) {
          this._setFormData(this.localizationAdded);
        }
        if (
          this.clonedLocalization &&
          Object.keys(this.clonedLocalization).length
        ) {
          this._setFormData(this.clonedLocalization);
          this.isDisableRespIdBtn = true;
        } else {
          this.isDisableRespIdBtn = false;
          this._setDefaultForm();
        }
      },
      (err) => {
        this._toastr.error(err.error.msg);
      }
    );
    this._subscriptions.add(countries);
  }

  public get getLocale(): string {
    const country: EachCountry = this.form.controls.selectedCountry.value;
    let language: EachLanguage = this.form.controls.selectedLanguage.value;
    if (country && !language) {
      language = country.lang[0];
    }
    return country && language
      ? `${language.short_code}_${country.short_Code}`
      : '';
  }

  get isNonEnglishLocale() {
    return !this.getLocale.includes('en');
  }

  private _setDependencyLocale() {
    if (
      this.dependencyComponent &&
      (this.form.controls.enableDependency.value ||
        this.localizationAdded?.hasDependency)
    ) {
      this.dependencyComponent.onLocaleSelected(
        this.getLocale,
        this.localizationAdded?.dependency
      );
    }
  }

  private _setSegmentationLocale() {
    if (
      this.segmentationComponent &&
      (this.form.controls.enableSegmentation.value ||
        this.form.controls.enableSegmentationDependentQuestion ||
        this.localizationAdded?.hasSegmentation)
    ) {
      this.segmentationComponent.onLocaleSelected(
        this.getLocale,
        this.localizationAdded?.segmentation
      );
    }
  }

  get isEligibleForOETerminationVisible() {
    const checks = [
      this.questionCategory !== '',
      this.questionType === Constants.QUESTION_TYPE.OPENENDED,
      this.questionCategory?.includes(Constants.DATA_QUALITY.QUESTION_CATEGORY),
      this.class === Constants.MAPPED_CLASS_TYPE.DATA_QUALITY,
    ];
    return checks.every(Boolean);
  }

  get isEligibleForOETerminationDefault() {
    const eligibleDefaultsToTrue = [
      this.isEligibleForOETerminationVisible,
      this.form.controls?.selectedLanguage?.value,
      this.form.controls?.selectedCountry?.value,
      this.form.controls?.selectedLanguage?.value?.transalte_code ===
        Constants.LANGUAGES_TRANSLATE_CODE.ENGLISH,
    ];
    return eligibleDefaultsToTrue.every(Boolean);
  }

  private _setEligibleForOETerm() {
    if (!this.isEligibleForOETerminationDefault) {
      this.form.controls?.eligibleForOETermination?.setValue(false);
      return;
    }
    this.form.controls?.eligibleForOETermination?.setValue(true);
  }

  selectCountry(country: EachCountry) {
    this.languages = country.lang;
    this.form.patchValue({ selectedCountry: country });
    this.form.patchValue({ selectedLanguage: country.lang[0] });
    this._setDependencyLocale();
    this._setSegmentationLocale();
    this._setEligibleForOETerm();
  }

  selectLanguage(language: EachLanguage) {
    this.form.controls.selectedLanguage.setValue(language);
    this._setDependencyLocale();
    this._setSegmentationLocale();
    this._setEligibleForOETerm();
  }

  clickRespId() {
    this.isDisableRespIdBtn = true;
    const screenerText = this.form.controls.screenerText?.value;
    if (screenerText) {
      if (!this.isEditMode && !screenerText.includes('%%respId%%')) {
        this.form.patchValue({ screenerText: `${screenerText} %%respId%%` });
      } else if (
        this.isEditMode &&
        !screenerText.includes(`%%${this.questionId}%%`)
      ) {
        this.form.patchValue({
          screenerText: `${screenerText} %%${this.questionId}%%`,
        });
      }
      return;
    }
    this.form.patchValue({
      screenerText: !this.isEditMode ? `%%respId%%` : `%%${this.questionId}%%`,
    });
  }

  changeScrennerText() {
    const screenerText = this.form.controls.screenerText?.value;
    if (screenerText && screenerText.includes('%%respId%%')) {
      this.isDisableRespIdBtn = true;
      return;
    }
    this.isDisableRespIdBtn = false;
  }

  saveQualityRules(event: DataQuality[]) {
    // data quality rules will recieve here
    this.qualityRules = event;
  }

  addLocalization() {
    if (this.form.status == 'INVALID') {
      this._toastr.warning(notifyMessage.warningMessage.INCOMPLETE_FIELDS);
      return;
    }
    const formValue = this.form.value;
    // remove default answer at first position
    formValue.answers.shift();
    const [isValid, invalidPlaceholderText] =
      this._checkScreenerTextValidation(formValue);
    if (!formValue.create_survey_component) {
      this._toastr.warning(
        notifyMessage.warningMessage.QUESTION_LIBRARY.REQUIRED_COMPONENT_TYPE
      );
      return;
    }
    if (!isValid) {
      this._toastr.warning(
        notifyMessage.warningMessage.QUESTION_LIBRARY.REQUIRED_RESPID.replace(
          '%%respId%%',
          invalidPlaceholderText
        )
      );
      return;
    }
    if (
      this.isNonEnglishLocale &&
      formValue.answers.some((a: AnswerOptionUI) => !a.translation)
    ) {
      return this._toastr.warning(
        notifyMessage.warningMessage.QUESTION_LIBRARY
          .REQUIRED_ANSWER_TRANSLATION
      );
    }

    const locale = `${formValue.selectedLanguage?.transalte_code}_${formValue.selectedCountry?.short_Code}`;
    let localeArr = [locale];
    if (formValue.selectedCountry?.short_Code === 'UK') {
      localeArr.push(`${formValue.selectedLanguage?.transalte_code}_GB`);
    }
    const isDuplicate = this.localizations?.find((loc: Localization) =>
      localeArr.includes(loc.locale)
    );
    if (isDuplicate) {
      this._toastr.warning(
        notifyMessage.warningMessage.QUESTION_LIBRARY.DUPLICATE_LOCALE
      );
      return;
    }
    const localization = this._prepareLocalizationObject(formValue);
    this.localizations.push(localization);
    this.localizationsChange.emit(this.localizations);
    this._toastr.success(
      notifyMessage.successMessage.QUESTION_LIBRARY.LOCALIZATION_ADDED
    );
  }

  private _prepareLocalizationObject(
    formValue: any,
    oldLocalization: Localization = {} as Localization
  ) {
    if (formValue.selectedCountry?.short_Code === 'UK') {
      formValue.selectedCountry.short_Code = 'GB';
    }
    let locale = oldLocalization.locale;
    if (formValue.selectedCountry) {
      locale = `${formValue.selectedLanguage?.transalte_code}_${formValue.selectedCountry?.short_Code}`;
    } else if (
      this.localizations?.length &&
      this.localizations[0].locale === 'all_ALL'
    ) {
      locale = this.localizations[0].locale;
    } else {
      this._toastr.warning(messages.WARNING_MESSAGE.BLOCKED_COUNTRY);
    }
    return {
      ...oldLocalization,
      locale,
      text: formValue.screenerText,
      placeholder: formValue.placeholder || '',
      answers: this._mapLocalizationAnswers(formValue),
      hasDependency: formValue.enableDependency || false,
      hasSegmentation: formValue.enableSegmentation || false,
      qualification_type: formValue.enableSegmentationDependentQuestion
        ? Constants.QUESTION_CATEGORY['DEPENDENT']
        : false,
      eligibleForOETermination: formValue.eligibleForOETermination || false,
      dynamic_profiler: {
        enable: formValue.enableDP.key,
        priority: formValue.dpPriority,
      },
      create_survey_position: formValue.create_survey_position
        ? formValue.create_survey_position.key
        : '',
      create_survey_component: formValue.create_survey_component
        ? formValue.create_survey_component.key
        : '',
      name_alias: formValue.name_alias,
      has_census_data: formValue.has_census_data || false,
      // Privacy policy question fields
      ...(this.isPrivacyPolicyQuestion && {
        non_gdpr: formValue.enableNonGdpr || false,
        pp_text: formValue.ppPlaceholderText,
        pp_modal_text: formValue.ppModalText,
      }),
      // dependency
      ...(formValue.enableDependency && {
        dependency: {
          parent_qualification_id:
            formValue.dependency.parentQuestion.qualification_id,
          match_criteria: formValue.dependency.condition,
          repeat: 1,
          condition: formValue.dependency.qualificationOptions.map(
            (opt: Answer) => opt.id
          ),
        },
      }),
      // segmentation
      ...(formValue.enableSegmentation &&
        this._segmentationQuesitionDetails(formValue)),
      // Data Quality
      ...(this.class === Constants.MAPPED_CLASS_TYPE.DATA_QUALITY && {
        data_quality: {
          enable: formValue.enableDataquality,
          quality_rules: this.qualityRules,
          eligible_for_oe_termination: formValue.eligibleForOETermination,
        },
        image_url: formValue.imageUrl,
      }),
    };
  }

  public _segmentationQuesitionDetails(formValue: any): object {
    const segmentationDetails: any = {
      segmentation: {
        parent_qualification_id:
          formValue.segmentation.parentQuestion.qualification_id,
      },
    };
    if (
      formValue.enableSegmentationDependentQuestion &&
      formValue.segmentation.answerOption
    ) {
      segmentationDetails.segmentation.childQuestion =
        formValue.segmentation.childQuestion.qualification_id;
      segmentationDetails.segmentation.condition = [
        formValue.segmentation.answerOption.id,
      ];
    }
    return segmentationDetails;
  }

  private _checkScreenerTextValidation(formValue: any): [boolean, string] {
    if (!this.isEditMode && !formValue?.screenerText?.includes('%%respId%%')) {
      return [false, '%%respId%%'];
    } else if (
      this.isEditMode &&
      !formValue?.screenerText?.includes(`%%${this.questionId}%%`)
    ) {
      return [false, `%%${this.questionId}%%`];
    }
    return [true, ''];
  }

  private _mapLocalizationAnswers(formValue: any) {
    const isEnglishLocalization =
      formValue.selectedLanguage?.transalte_code === 'en';
    return formValue.answers.map((answer: AnswerOptionUI) => {
      return {
        condition_code: Number(answer.id),
        answer_id: String(answer.id),
        eng_text: isEnglishLocalization ? answer.text : answer.translation,
        local_text: answer.text,
      };
    });
  }

  resetForm() {
    const selectedCountry = this.countries.find(
      (country: EachCountry) => country.short_Code === 'US'
    );
    this.form.reset({
      selectedCountry: selectedCountry,
      selectedLanguage: selectedCountry?.lang.find(
        (lang: EachLanguage) => lang.transalte_code === 'en'
      ),
      enableDP: {
        key: false,
        value: 'false',
      },
      dpPriority: 0,
      screenerText: '',
      placeholder: '',
      enableDependency: false,
      eligibleForOETermination: false,
      enableDataquality: false,
    });
    const answers = this.form.get('answers') as FormArray;
    while (answers.length) {
      answers.removeAt(0);
    }
    answers.push(
      this._fb.group({
        id: Constants.LOCALIZATION.ANSWERS.DEFAULT_CONDITION_CODE,
        text: '',
        translation: '',
        totalSelectedText: 'Total Selected: 0',
      })
    );
    this.isDisableRespIdBtn = false;
  }

  private _setFormData(localization: Localization) {
    const [langCode, cntCode] = localization.locale.split('_');
    const selectedCountry = this.countries.find(
      (country) => country.short_Code === cntCode || country.pl_code === cntCode
    );
    const position = Constants.POSITION_OPTIONS.find(
      (opt) => opt.key === (localization.create_survey_position || 0)
    );
    const componentType = Constants.ALL_COMPONENT_OPTIONS.filter(
      (component) => component.type === position?.type
    );
    const getComponentType = componentType.find(
      (component) => component.key === localization.create_survey_component
    );

    this.create_survey_position = Constants.POSITION_OPTIONS;
    this.create_survey_component = componentType;

    this.form.patchValue({
      selectedCountry: selectedCountry,
      selectedLanguage:
        selectedCountry?.lang.find(
          (language) => language.transalte_code === langCode
        ) || '',
      placeholder: localization.placeholder || '',
      screenerText: localization.text,
      enableDP: Constants.DP_OPTIONS.find(
        (opt) =>
          localization.dynamic_profiler &&
          opt.key === localization.dynamic_profiler.enable
      ),
      dpPriority: localization.dynamic_profiler?.priority || 0,
      enableDependency: localization.hasDependency,
      enableSegmentation: localization.hasSegmentation,
      eligibleForOETermination:
        localization.data_quality?.eligible_for_oe_termination || false,
      create_survey_position: position,
      create_survey_component: getComponentType || Constants.ADVANCE_DEFAULT,
      name_alias: localization.name_alias,
      has_census_data: localization.has_census_data,
      enableDataquality: localization.data_quality?.enable || false,
      imageUrl: localization.image_url,
      ...(this.isPrivacyPolicyQuestion && {
        enableNonGdpr: localization.non_gdpr || false,
        ppPlaceholderText: localization.pp_text || '',
        ppModalText: localization.pp_modal_text || '',
      }),
    });

    // set answers
    this.form.setControl(
      'answers',
      this._fb.array(this._sortAndMapAnswers(localization.answers))
    );
  }

  private _sortAndMapAnswers(answers: AnswerOptionAPI[] = []) {
    // Sort answers in descending (down) order of their condition code
    let downSortedAnswers = answers.sort(
      (a, b) => b.condition_code - a.condition_code
    );
    // Add empty answer field at the beginning of that sorted array
    downSortedAnswers = [
      {
        condition_code: this._getConditionCodeForEmptyRow(downSortedAnswers),
        answer_id: '',
        eng_text: '',
      },
      ...downSortedAnswers,
    ];
    return downSortedAnswers.map((answer: AnswerOptionAPI, i: number) =>
      this._fb.group({
        id: [answer.condition_code, Validators.required],
        text: [answer.local_text || ''],
        translation: [answer.eng_text || ''],
        totalSelectedText: [
          `Total Selected: ${answer.stats?.totalSelected || 0}`,
        ],
      })
    );
  }

  private _getOptionForEmptyRow(answers: AnswerOptionUI[]) {
    const filteredArray = answers.filter(
      (ans) =>
        Number(ans.id) <
        Constants.LOCALIZATION.ANSWERS.ANCHOR_CONDITION_CODE.MIN
    );
    return filteredArray && filteredArray.length
      ? Number(filteredArray[0].id) + 1
      : Constants.LOCALIZATION.ANSWERS.DEFAULT_CONDITION_CODE;
  }

  private _getConditionCodeForEmptyRow(answers: AnswerOptionAPI[]) {
    const filteredArray = answers.filter(
      (ans) =>
        Number(ans.condition_code) <
        Constants.LOCALIZATION.ANSWERS.ANCHOR_CONDITION_CODE.MIN
    );
    return filteredArray && filteredArray.length
      ? Number(filteredArray[0].condition_code) + 1
      : Constants.LOCALIZATION.ANSWERS.DEFAULT_CONDITION_CODE;
  }

  selectQualificationPosition(category: QualificationOptions) {
    this.create_survey_component = this._getComponentOptions(category.type);
    this.form.patchValue({
      create_survey_position: category,
      create_survey_component: '',
    });
  }

  selectQualificationComponent(category: QualificationOptions) {
    this.form.patchValue({ create_survey_component: category });
  }

  private _getComponentOptions(category: string) {
    return Constants.ALL_COMPONENT_OPTIONS.filter((component) => {
      return component.type === category;
    });
  }

  // Method to call the child component's method
  callGetChildQuestionsMethod() {
    if (this.segmentationComponent) {
      this.segmentationComponent.getChildQuestions(
        this.getLocale,
        this.form.controls.enableSegmentationDependentQuestion.value
      );
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
